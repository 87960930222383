import ComponentWithFloatingIconDownload from '@/components/ComponentWithFloatingIconDownload';

export const ANALYTIC_STORY_WRAPPER = 'ANALYTIC_STORY_WRAPPER';

export default {
  name: 'AnalyticsStoryWrapper',

  components: {
    ComponentWithFloatingIconDownload,
  },

  props: {
    accountInfo: {
      type: Object,
      required: true,
    },
    story: {
      type: Object,
      required: true,
    },
    extraConfig: {
      type: Object,
    },
    copyAd: {
      type: Function,
    },
  },
};
